import React from 'react';
import '../styles/MaintenancePage.css'; // Assicurati di creare questo file CSS
import maintenanceImage from '../assets/maintenance.svg'; // Cambia con il percorso dell'immagine che desideri utilizzare

const MaintenancePage = () => {
  return (
    <div className="maintenance-container">
      
      <div className="maintenance-content">
        
        <div className="maintenance-text">
          <h1 className='titolo'>Stiamo Lavorando per Te!</h1>
          <p className='testo'>Ci scusiamo per l'inconveniente, ma la pagina è attualmente in manutenzione.</p>
          <p className='testo'>Ritorna a trovarci presto!</p>
        </div>
        
        <div>            
            <img src={maintenanceImage} alt="Manutenzione" className="maintenance-image" />
        </div>   
         
      </div>
    
    </div>
  );
};

export default MaintenancePage;
