import React, { useState }  from 'react';
import "../styles/Footer.css";
import AppTerms from '../pages/AppTerms.js';
import AppPrivacy from '../pages/AppPrivacy.js';
import ModalComponent from './modalcomponent.js';

const Footer = () => {
    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const [isPoliceOpen, setIsPoliceOpen] = useState(false);

    const handlePoliceTerms = (event) => {
        event.preventDefault();
        setIsPoliceOpen(true);
    }

    const handleOpenTerms = (event) => {
        event.preventDefault();
        setIsTermsOpen(true);
    }
    const handleCloseModal = () => {
        setIsTermsOpen(false);
        setIsPoliceOpen(false);
    };

    return (
        <div className='footer-container'>
            <footer className="footer">
                <p className='footer-text'>&copy; {new Date().getFullYear()} Just4Fun. Tutti i diritti riservati. | Contatti: <a className='mailto' href="mailto:info@just4fun.it">info@just4fun.it</a><br/>
                    <a className="item_link-terms" href="#" onClick={handleOpenTerms}> Termini e Condizioni</a> | <a target='_blank' className="item_link-terms" href="#" onClick={handlePoliceTerms}>Politica sulla Privacy</a>.
                </p>
                <ModalComponent isOpen={isTermsOpen} onClose={handleCloseModal} content={<AppTerms />} />
                <ModalComponent isOpen={isPoliceOpen} onClose={handleCloseModal} content={<AppPrivacy />} />
            </footer>
        </div>
    );
};

export default Footer;
