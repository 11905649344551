import React from 'react';
import '../styles/CommonText.css';
import '../App.css';

const AppTerms = () => {
  return (
    <div className='page-container'>
      <h1>Just4Fun APP - Termini di Servizio</h1>
      <hr></hr>
      <h3>1. Accettazione dei Termini</h3>
      <p>Utilizzando il bot/app Discord "Just4Fun APP" (di seguito "l'App"), accetti di essere vincolato dai presenti Termini di Servizio (di seguito "Termini"). Se non accetti questi Termini, non devi utilizzare l'App.</p>

      <h3>2. Modifiche ai Termini</h3>
      <p>Ci riserviamo il diritto di modificare questi Termini in qualsiasi momento. Ti informeremo di eventuali modifiche significative pubblicando i nuovi Termini sul nostro sito web o tramite un annuncio sul server Discord. L'uso continuato dell'App dopo tali modifiche costituisce accettazione dei nuovi Termini.</p>

      <h3>3. Utilizzo dell'App</h3>
      <h4>3.1 Uso Consentito</h4>
      <p>L'App è destinata all'uso personale e non commerciale. Sei autorizzato a utilizzare l'App per interagire con il server Discord in conformità con i presenti Termini e le norme della comunità Discord.</p>
      <h4>3.2 Restrizioni</h4>
      <p>Non devi:
          <ul>
              <li>Utilizzare l'App per scopi illegali o non autorizzati.</li>
              <li>Tentare di compromettere la sicurezza l'App o dei server Discord su cui è utilizzato.</li>
              <li>Modificare, adattare o hackerare l'App.</li>
              <li>Utilizzare l'App per spam o altre attività dannose.</li>
          </ul>
      </p>

      <h3>4. Privacy</h3>
      <p>L'App può raccogliere e utilizzare dati personali in conformità con la nostra Politica sulla Privacy, che è incorporata nei presenti Termini per riferimento. Utilizzando l'App, acconsenti alla raccolta e all'uso dei tuoi dati come descritto nella nostra Politica sulla Privacy.</p>

      <h3>5. Limitazione di Responsabilità</h3>
      <p>L'App è fornito "così com'è" senza garanzie di alcun tipo. Non garantiamo che l'App sarà disponibile ininterrottamente o privo di errori. Non saremo responsabili per eventuali danni derivanti dall'uso o dall'impossibilità di utilizzare l'App.</p>

      <h3>6. Risoluzione</h3>
      <p>Possiamo sospendere o terminare il tuo accesso all'App in qualsiasi momento, senza preavviso, per qualsiasi motivo, incluso ma non limitato alla violazione dei presenti Termini. Puoi interrompere l'utilizzo dell'App in qualsiasi momento disinstallandola o smettendo di utilizzarla.</p>

      <h3>7. Legge Applicabile</h3>
      <p>Questi Termini sono regolati e interpretati in conformità con le leggi. Qualsiasi controversia derivante da o relativa a questi Termini sarà soggetta alla giurisdizione esclusiva dei tribunali.</p>

      <h3>8. Contatti</h3>
      <p>Se hai domande sui presenti Termini, puoi contattarci a info@just4fun.it.</p>
      
      </div>
  );
};

export default AppTerms;