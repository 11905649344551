import React from 'react';
import '../styles/Home.css'; // Assicurati di importare il file CSS
import maintenanceImage from '../assets/maintenance.svg'; // Cambia con il percorso dell'immagine che desideri utilizzare

const Home = () => {
  return (
  <div>
    <div className="contact-container">
      <h1 className='titolo'>Benvenuti nella community Just4Fun!</h1>
      <p className='descrizione' >Esplora la nostra community e scopri di più su di noi!</p>
    </div>
    <div>
      <div className="maintenance-container">
          
          <div className="maintenance-content">
            
            <div className="maintenance-text">
              <h1 className='titolo'>Stiamo Lavorando per Te!</h1>
              <p className='testo'>Ci scusiamo per l'inconveniente, ma la pagina è attualmente in manutenzione.</p>
              <p className='testo'>Ritorna a trovarci presto!</p>
            </div>
            
            <div>            
                <img src={maintenanceImage} alt="Manutenzione" className="maintenance-image" />
            </div>   
            
          </div>
    
        </div>
    </div>
  </div>

  );
};

export default Home;