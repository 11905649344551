import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer'; // Importa il componente Footer
import Home from './pages/Home';
import MaintenancePage from './pages/MaintenancePage';
import ChiSiamo from './pages/ChiSiamo';
import Eventi from './pages/Eventi';
import Contatti from './pages/Contatti';
import AppTerms from './pages/AppTerms';
import AppPrivacy from './pages/AppPrivacy';
import './App.css';
import Servizi from './pages/Servizi';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/chi-siamo" element={<MaintenancePage />} />
            {/* <Route path="/chi-siamo" element={<ChiSiamo />} /> */}
            <Route path="/eventi" element={<MaintenancePage />} />
            {/* <Route path="/eventi" element={<Eventi />} /> */}
            <Route path="/contatti" element={<Contatti />} />
            <Route path="/servizi" element={<Servizi />} />
            <Route path="/just4funapp-terms" element={<AppTerms />} />
            <Route path="/just4funapp-privacy" element={<AppPrivacy />} />
          </Routes>
        </div>
        <Footer /> {/* Aggiungi il footer qui */}
      </div>
    </Router>
  );
}

export default App;
