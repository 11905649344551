import React from 'react';
import '../styles/Servizi.css'; // Assicurati di importare il file CSS
import discordLogo from '../assets/discord-round-color-icon.png'; // Importa direttamente l'immagine
import telegramLogo from '../assets/Telegram_2019_Logo.svg.webp'; // Importa l'immagine di Telegram
import youtubeLogo from '../assets/youtube-logo.png';
import twitchLogo from '../assets/twitch-logo.png';
import maintenanceImage from '../assets/maintenance.svg'; // Cambia con il percorso dell'immagine che desideri utilizzare

const Servizi = () => {
  return (
    <div>
      <div className="contact-container">
        <div>
          <h1 className="titolo">I servizi offerti dalla Community</h1>
        </div>

        {/* Wrapper per le sezioni social */}
        <div className="social-wrapper">
          <div className="social-discord">
            <a href="https://discord.gg/A5yyPaw" target="_blank" rel="noopener noreferrer">
              <img src={discordLogo} alt="Discord" className="social-icon" />
            </a>
          </div>
          <div className="social-telegram">
            <a href="https://t.me/Just4FunOGGICIALGROUP" target="_blank" rel="noopener noreferrer">
              <img src={telegramLogo} alt="Telegram" className="social-icon" />
            </a>
          </div>
          <div className="social-youtube">
            <img src={youtubeLogo} alt="YouTube" className="social-icon" />
          </div>
          <div className="social-twitch">
            <img src={twitchLogo} alt="Twitch" className="social-icon" />
          </div>
        </div>

        <br />
        <br />

        <div>
          <h2 className="sottotitolo">Assistenza Tecnica e molto altro disponibili sul nostro Discord!</h2>
        </div>

        <br />
        <br />
        <br />
        <br />

        <div className="link-section">
          {/* Aggiungi classi Bootstrap per trasformare i link in pulsanti */}
          <a
            href="http://just4fun.it:5000"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary btn-sm custom-btn m-2"
          >
            HPE-Server DSM
          </a>
          <a
            href="http://just4fun.it:8080"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary btn-sm custom-btn m-2"
          >
            SpeedTest
          </a>
          <a
            href="http://just4fun.it:8097"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success btn-sm custom-btn m-2"
          >
            JellyFlix
          </a>
        </div>
      </div>

      <div>
        <div className="maintenance-container">
          <div className="maintenance-content">
            <div className="maintenance-text">
              <h1 className="titolo">Stiamo Lavorando per Te!</h1>
              <p className="testo">Ci scusiamo per l'inconveniente, ma la pagina è attualmente in manutenzione.</p>
              <p className="testo">Ritorna a trovarci presto!</p>
            </div>

            <div>
              <img src={maintenanceImage} alt="Manutenzione" className="maintenance-image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servizi;
