import React from 'react';
import '../styles/CommonText.css';
import '../App.css';

const AppPrivacy = () => {
  return (
    <div className='page-container'>
      <h1>Just4Fun APP - Politica sulla Privacy</h1>
      <hr></hr>
      <h3>1. Introduzione</h3>
      <p>Benvenuto/a alla Politica sulla Privacy di Just4Fun APP (di seguito "l'App"). La tua privacy è importante per noi. Questa Politica sulla Privacy descrive come raccogliamo, utilizziamo e proteggiamo le informazioni personali che ci fornisci quando utilizzi l'App.</p>

      <h3>2. Informazioni che Raccogliamo</h3>
      <h4>2.1 Informazioni Personali:</h4>
      <p>Informazioni che possono identificarti personalmente, come il tuo nome utente Discord, ID utente Discord e altre informazioni che scegli di fornire.</p>
      <h4>Informazioni di Utilizzo:</h4>
      <p>Dati relativi al tuo utilizzo dell'App, come le interazioni con i comandi del bot, la frequenza di utilizzo e altre statistiche di utilizzo.</p>

      <h3>3. Come Utilizziamo le Informazioni</h3>
      <p>Le informazioni raccolte vengono utilizzate per i seguenti scopi:</p>
      <h4>3.1 Fornire e Migliorare i Servizi:</h4>
      <p>Per gestire, mantenere e migliorare le funzionalità dell'App.</p>
      <h4>3.2 Comunicazioni:</h4>
      <p>Per comunicare con te riguardo aggiornamenti, modifiche ai termini di servizio o altre informazioni rilevanti.</p>
      <h4>3.3 Sicurezza:</h4>
      <p>Per monitorare e proteggere la sicurezza dell'App e degli utenti.</p>

      <h3>4. Condivisione delle Informazioni</h3>
      <p>Non vendiamo, scambiamo o affittiamo le tue informazioni personali a terze parti. Potremmo condividere le informazioni solo nelle seguenti circostanze:</p>
      <h4>4.1 Con il tuo Consenso:</h4>
      <p>Condivideremo le informazioni personali solo con il tuo consenso esplicito.</p>
      <h4>4.2 Per Obblighi Legali:</h4>
      <p>Potremmo divulgare le informazioni personali se richiesto dalla legge o in risposta a validi procedimenti legali.</p>

      <h3>5. Protezione delle Informazioni</h3>
      <p>Adottiamo misure di sicurezza adeguate per proteggere le informazioni personali da accessi non autorizzati, alterazioni, divulgazioni o distruzioni. Tuttavia, nessun metodo di trasmissione su Internet o metodo di archiviazione elettronica è sicuro al 100%.</p>

      <h3>6. Conservazione delle Informazioni</h3>
      <p>Conserviamo le informazioni personali solo per il tempo necessario a soddisfare gli scopi per cui sono state raccolte, a meno che non sia richiesto o consentito dalla legge un periodo di conservazione più lungo.</p>

      <h3>7. Diritti degli Utenti</h3>
      <p>Hai il diritto di accedere, correggere o eliminare le informazioni personali che raccogliamo. Puoi esercitare questi diritti contattandoci all'indirizzo email fornito di seguito.</p>

      <h3>8. Modifiche alla Politica sulla Privacy</h3>
      <p>Ci riserviamo il diritto di modificare questa Politica sulla Privacy in qualsiasi momento. Ti informeremo di eventuali modifiche significative pubblicando la nuova Politica sulla Privacy sul nostro sito web o tramite un annuncio sul server Discord. L'uso continuato dell'App dopo tali modifiche costituisce accettazione della nuova Politica sulla Privacy.</p>

      <h3>9. Contatti</h3>
      <p>Se hai domande o dubbi riguardanti questa Politica sulla Privacy, puoi contattarci a info@just4fun.it.</p>
      
      </div>
  );
};

export default AppPrivacy;