import React from 'react';
import '../styles/Contatti.css'; // Assicurati di importare il file CSS
import discordLogoEXT from '../assets/logo_discord.png'; // Importa direttamente l'immagine
import discordLogo from '../assets/discord-round-color-icon.png'; // Importa direttamente l'immagine
import telegramLogo from '../assets/Telegram_2019_Logo.svg.webp'; // Importa l'immagine di Telegram
import youtubeLogo from '../assets/youtube-logo.png';
import twitchLogo from '../assets/twitch-logo.png';

const communityFounders = [
  {
    name: 'Simorz',
    avatar: 'https://yt3.ggpht.com/_CDlbR6FZlbXelrqZT3BWXSmchFF6V7WuFGxhmo_7MrF6WIU1fo5MQxIAVvMVML5L4MBDCskXH4=s176-c-k-c0x00ffffff-no-rj-mo',
    youtube: 'https://www.youtube.com/@simorz',
    twitch: 'https://twitch.tv/mariorossi',
  },
  {
    name: 'Devix',
    avatar: 'https://via.placeholder.com/100',
    youtube: null,
    twitch: null,
  },
];

const communityStaff = [
  {
    name: 'Davide03gm',
    avatar: 'https://via.placeholder.com/100',
    youtube: null,
    twitch: null,
  },
  {
    name: 'Ludovix',
    avatar: 'https://via.placeholder.com/100',
    youtube: null,
    twitch: null,
  },
  {
    name: 'Zocch',
    avatar: 'https://via.placeholder.com/100',
    youtube: null,
    twitch: null,
  },
  {
    name: 'Ariel',
    avatar: 'https://via.placeholder.com/100',
    youtube: null,
    twitch: null,
  },
  {
    name: 'Bluestone',
    avatar: 'https://via.placeholder.com/100',
    youtube: null,
    twitch: null,
  },
];

const Contatti = () => {
  return (
    <div className="contact-container">
      <h1 className='titolo'>Contattaci</h1>
      <p>Per maggiori informazioni, scrivici a <a href="mailto:info@just4fun.it">info@just4fun.it</a></p>
      
      {/* <div className="social-section">
        <h4 className='sottotitolo'>Entra sul nostro Discord!</h4>
        <a href="https://discord.gg/A5yyPaw" target="_blank" rel="noopener noreferrer">
          <img src={discordLogoEXT} alt="Discord" className="social-icon" />
        </a>
      </div> */}

      <div>
        <h2 className='sottotitolo'>I nostri Social</h2>
      </div>

      {/* Wrapper per le sezioni social */}
      <div className="social-wrapper">
        <div className="social-discord">
          {/* <h2 className='sottotitolo'>Entra sul nostro Server Discord!</h2> */}
          <a href="https://discord.gg/A5yyPaw" target="_blank" rel="noopener noreferrer">
            <img src={discordLogo} alt="Discord" className="social-icon" />
          </a>
        </div>
        <div className="social-telegram">
          {/* <h2 className='sottotitolo'>Entra sul nostro Canale Telegram!</h2> */}
          <a href="https://t.me/Just4FunOGGICIALGROUP" target="_blank" rel="noopener noreferrer">
            <img src={telegramLogo} alt="Telegram" className="social-icon" />
          </a>
        </div>
        <div className="social-youtube">
          {/* <h2 className='sottotitolo'>Entra sul nostro Server Discord!</h2> */}
          {/* <a href="https://discord.gg/A5yyPaw" target="_blank" rel="noopener noreferrer"> */}
            <img src={youtubeLogo} alt="Discord" className="social-icon" />
          {/* </a> */}
        </div>
        <div className="social-twitch">
          {/* <h2 className='sottotitolo'>Entra sul nostro Server Discord!</h2> */}
          {/* <a href="https://discord.gg/A5yyPaw" target="_blank" rel="noopener noreferrer"> */}
            <img src={twitchLogo} alt="Discord" className="social-icon" />
          {/* </a> */}
        </div>
      </div>
      
      <div className="community-section">
        <h2 className='titolo'>Founders</h2>
        <div className="community-members">
          {communityFounders.map((member, index) => (
            <div className="member-card" key={index}>
              <img src={member.avatar} alt={`${member.name}'s avatar`} className="member-avatar" />
              <h3>{member.name}</h3>
              <div className="social-links">
                <a href={member.youtube} target="_blank" rel="noopener noreferrer">YouTube</a>
                <a href={member.twitch} target="_blank" rel="noopener noreferrer">Twitch</a>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="community-section">
        <h2 className='titolo'>Staff</h2>
        <div className="community-members">
          {communityStaff.map((member, index) => (
            <div className="member-card" key={index}>
              <img src={member.avatar} alt={`${member.name}'s avatar`} className="member-avatar" />
              <h3>{member.name}</h3>
              <div className="social-links">
                <a href={member.youtube} target="_blank" rel="noopener noreferrer">YouTube</a>
                <a href={member.twitch} target="_blank" rel="noopener noreferrer">Twitch</a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Contatti;
