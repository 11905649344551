import React, { useEffect } from 'react';
import "../styles/ModalPage.css";

const ModalComponent = ({ isOpen, onClose, content }) => {
    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [isOpen]);

    if (!isOpen) return null;

    const handleOverlayClick = (e) => {
        if (e.target.className === 'modal-overlay') {
            onClose();
        }
    };

    return (
        <div className="modal-overlay" onClick={handleOverlayClick}>
            <div className="modal">
                <button className="modal-close" onClick={onClose}>
                    <span className="close-icon">✕</span> {/* O puoi usare un'icona */}
                </button>
                <div className="modal-content">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default ModalComponent;

