import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';
import logo from '../assets/j4f_logo_trasparent.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Alterna lo stato di apertura del menu
  };

  return (
    <nav className="navbar">
      <Link to="/" className="navbar-logo">
        <img src={logo} alt="just4fun logo" />
        <h2 className="navbar-title">Just4Fun ITA</h2>
      </Link>
      
      {/* Hamburger menu button */}
      <div className="menu-icon" onClick={toggleMenu}>
        {/* Cambia l'icona da hamburger a X */}
        {isMenuOpen ? <span className="close-icon">✕</span> : <span className="hamburger-icon">☰</span>}
      </div>
      
      {/* Dropdown menu in a div */}
      <div className={`dropdown-container ${isMenuOpen ? 'open' : ''}`}>
        <ul className="navbar-links">
          <li><Link to="/" className="buttons" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/eventi" className="buttons" onClick={toggleMenu}>Eventi</Link></li>
          <li><Link to="/servizi" className="buttons" onClick={toggleMenu}>Servizi</Link></li>
          <li><Link to="/contatti" className="buttons" onClick={toggleMenu}>Contatti</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
